@import "@/assets/styles/variables.scss";






















































.our-talents {
  img {
    margin-right: 20px;
  }
}
